import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    border-radius: 8px;
    @media (max-width: 600px) {
        padding: 10px;
    }
`;

export const Title = styled.h3`
    margin-top: 20px;
    color: #333;
    text-align: center;
`;

export const StylishButton = styled.button`
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
        background-color: #45a049;
    }
`;

export const DatePickerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        flex-direction: column;
    }

    .date-picker {
        width: 48%;

        @media (max-width: 600px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
`;
