import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { APIProvider } from './context/axsioSetup';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

<Router> {/* Router muss hier um die Provider gelegt werden */}
    <APIProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </APIProvider>
  </Router>

  </React.StrictMode>
);

// Wenn Sie die Leistung Ihrer App messen möchten, übergeben Sie eine Funktion
// zum Protokollieren der Ergebnisse (z. B. reportWebVitals(console.log))
// oder senden Sie diese an einen Analyseendpunkt. Weitere Informationen: https://bit.ly/CRA-vitals
reportWebVitals();


