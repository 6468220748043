import React, { useState } from "react";
import format from "date-fns/format";
import { Tooltip } from 'react-tooltip';
import CustomEventTooltip from "./CustomEventTooltip";


const CustomEventWithContext = ({ event,onMove, onDelete, onCopy }) => {


  return (
    <>
    <div data-tooltip-id={`event-tooltip-${event.event_id}`} className="rbc-events-container">
    <div className="event-title">
      <span className="event-start">{format(new Date(event.start_datetime), 'HH:mm')}</span> - {event.title}
    </div>
    <Tooltip id={`event-tooltip-${event.event_id}`}>
      <CustomEventTooltip event={event} />
    </Tooltip>
  </div>

  </>
  );
};

export default CustomEventWithContext