import React from 'react'

function CustomResourceHeader({ label, resource }) {
    return (
      <div
        style={{
          backgroundColor: resource.color, // Farbe aus der Ressource
          color: '#fff', // Textfarbe (für Lesbarkeit)
          textAlign: 'center', // Zentrierter Text
          padding: '5px', // Abstand
          borderRadius: '5px', // Optional: Abgerundete Ecken
          fontSize:'13px',

        }}
      >
        {label} {/* Standard-Label des Headers */}
      </div>
    );
  };

export default CustomResourceHeader