import styled, { keyframes } from 'styled-components';

// Color Animation for background
const colorAnimation = keyframes`
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
`;

export const Container = styled.div`
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  background: linear-gradient(90deg, rgba(148, 5, 52, 0.61) 18%, rgba(118, 110, 93, 1) 48%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: ${colorAnimation} 10s linear infinite;
  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    position: relative;
    transform: none;
    left: 0;
    top: 0;
  }
`;

export const Background = styled.div`
  width: 100%;
  max-width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    position: relative;
    transform: none;
    left: 0;
    top: 0;
  }
`;

const Shape = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
`;

export const ShapeFirst = styled(Shape)`
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
  @media (max-width: 768px) {
    left: -10px;
    top: -200px;
    height: 150px;
    width: 150px;
  }
`;

export const ShapeLast = styled(Shape)`
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -200px;
  @media (max-width: 768px) {
    height: 150px;
    width: 150px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.6);
  padding: 40px 30px;
  padding-bottom: 120px; /* Increased to make space for the feet */
  padding-left: 70px;
  padding-right: 70px;
  @media (max-width: 768px) {
    width: 100%;
   
    height: auto;
    position: relative;
    transform: none;
    top: 0%;
  left: -20%;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const FormHeader = styled.h2`
  font-size: 32px;
  font-weight: 900;
  line-height: 42px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Label = styled.label`
  display: block;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  color: whitesmoke;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Input = styled.input`
  display: block;
  height: 50px;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  padding: 0 15px;
  padding-right: 40px; /* Adjust padding to accommodate the icon */
  margin-top: 8px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
  }

  @media (max-width: 768px) {
    height: 40px;
    font-size: 14px;
  }
`;

export const Button = styled.button`
  margin-top: 40px;
  width: 85%;
  background-color: #1845ad;
  color: #ffffff;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #23a2f6;
    transform: translateY(-3px);
  }
  @media (max-width: 768px) {
    padding: 10px 0;
    font-size: 16px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;











// Keyframe animations for rotation
const animate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const animate2 = keyframes`
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
`;

// Ring component with animations and hover effects
export const Ring = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  & i {
    position: absolute;
    inset: 0;
    border: 2px solid #fff;
    transition: 0.5s;
  }

  & i:nth-child(1) {
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    animation: ${animate} 6s linear infinite;
  }

  & i:nth-child(2) {
    border-radius: 41% 44% 56% 59% / 38% 62% 63% 37%;
    animation: ${animate} 4s linear infinite;
  }

  & i:nth-child(3) {
    border-radius: 41% 44% 56% 59% / 38% 62% 63% 37%;
    animation: ${animate2} 10s linear infinite;
  }

  &:hover i {
    border: 6px solid var(--clr);
    filter: drop-shadow(0 0 20px var(--clr));
  }
`;
