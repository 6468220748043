import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Importieren des Navigations-Hooks
import { SettingsContext } from '../../context/SettingsContext';

function SettingsPage() {
  const { settings, updateSettings } = useContext(SettingsContext);
  const navigate = useNavigate(); // Hook zum Navigieren initialisieren

  const handleTimeslotsChange = (e) => {
    updateSettings({ timeslots: parseInt(e.target.value, 10) });
  };

  const handleStepChange = (e) => {
    updateSettings({ step: parseInt(e.target.value, 10) });
  };

  const handleColorChange = (e) => {
    updateSettings({ eventColor: e.target.value });
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Zurück-Button */}
      <button
        style={{
          marginBottom: '20px',
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          borderRadius: '5px',
          border: '1px solid #ccc',
          backgroundColor: '#f5f5f5',
        }}
        onClick={() => navigate(-1)} // Navigiert zur vorherigen Seite
      >
        Zurück
      </button>

      <h2>Settings</h2>
      <form>
        <div>
          <label>Timeslots: </label>
          <input
            type="number"
            value={settings.timeslots}
            onChange={handleTimeslotsChange}
          />
        </div>
        <div>
          <label>Step: </label>
          <input
            type="number"
            value={settings.step}
            onChange={handleStepChange}
          />
        </div>
        <div>
          <label>Event Color: </label>
          <input
            type="color"
            value={settings.eventColor}
            onChange={handleColorChange}
          />
        </div>
      </form>
    </div>
  );
}

export default SettingsPage;
