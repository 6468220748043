import React, { memo, useCallback } from 'react';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';

const CustomToolbarSched = memo(({ view, onView, multiCount, onMultiChange, date, setPlannerActive, setMultiSettings }) => {
  console.log('data', date);

  const formattedDate = format(new Date(date), 'PPP', { locale: de }); // Format: Tag Monat Jahr in Deutsch

  const handleViewChange = useCallback(
    (event) => {
      const newView = event.target.value;
      if (onView) onView(newView);
    },
    [onView]
  );

  const handleMultiChange = useCallback(
    (event) => {
      const count = parseInt(event.target.value, 10);
      if (onMultiChange) onMultiChange(count);
    },
    [onMultiChange]
  );

  const resetSettings = () => {
    setPlannerActive(false);
    setMultiSettings({ view: 'day', multiCount: 1 });
    localStorage.setItem('plannerActive', JSON.stringify(false));
    localStorage.setItem('multiViewSettings', JSON.stringify({ view: 'day', multiCount: 1 }));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', maxWidth: '800px', height: '10px' }}>
      <select value={view} onChange={handleViewChange} style={{ padding: '5px', borderRadius: '4px' }}>
        <option value="day">Day</option>
        <option value="week">Week</option>
        <option value="multiDay">Multi Days</option>
        <option value="multiWeek">Multi Weeks</option>
      </select>

      {(view === 'multiDay' || view === 'multiWeek') && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <label>Count:</label>
          <input
            type="number"
            min="1"
            max="10"
            value={multiCount}
            onChange={handleMultiChange}
            style={{ padding: '5px', borderRadius: '4px' }}
          />
        </div>
      )}

      <div>
        <span style={{ fontWeight: 'bold' }}>Datum: </span>
        {formattedDate}
      </div>

      <button
        onClick={resetSettings}
        style={{
          padding: '5px 10px',
          borderRadius: '4px',
          backgroundColor: '#007BFF',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Reset to Calendar
      </button>
    </div>
  );
});

export default CustomToolbarSched;
