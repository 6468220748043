import styled from "styled-components";

export const FormContainer = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60%;
  overflow-y: auto;
  position: fixed; /* Fixed position to keep it centered */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centering */
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 95%;
  }
`;

// Beispiel für andere Komponenten
export const Header = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media print {
    display: block;
    padding: 0;
    margin: 0;
  }
`;

export const FormHeader1 = styled.h3`
  font-size: 1.8rem;
  margin: 0;
  color: #333;

  @media print {
    font-size: 1.5rem;
  }
`;

// Beispiel für den Rest Ihrer Styled Components
export const InputEv = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);

  @media print {
    box-shadow: none;
    margin-bottom: 5px;
  }
`;

export const SelectEmployee = styled.select`
  color: yellow;
  width: 100%;
  padding: 10px;
  height: 200px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 1rem;
  color: #333;
`;

export const MitarbeiterPicker = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  color: white;
  flex-wrap: wrap;
  gap: 5px;
`;

export const DataContainerDatePicker = styled.div`
  width: 70%;
  display: ${(props) => (props.showrepeatComponent ? 'none' : 'flex')};
  padding: 7px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: blue;
  border: 4px solid #ccc;
  box-shadow: -17px 19px 13px 0px rgba(226, 226, 242, 0.75) inset;
  -webkit-box-shadow: -17px 19px 13px 0px rgba(226, 226, 242, 0.75) inset;
  -moz-box-shadow: -17px 19px 13px 0px rgba(226, 226, 242, 0.75) inset;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  display: ${(props) => (props.showRepeatComponent ? 'none' : 'block')};

  &:hover {
    background-color: #0056b3;
  }
`;
