import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './toolbar.css'
import de from 'date-fns/locale/de'; // Import German locale
function CustomToolbar({
  label,
  onNavigate,
  setView,
  view,
  selectedDate,
  setSelectedDate,
  messages,
  togglePlanner,
  plannerActive,
  setPlannerActive
}) {
  // Verfügbare Ansichten mit Fallback-Übersetzungen


  return (
    <div className="rbc-toolbar">
      {/* Navigationsbuttons */}
      <div className="rbc-btn-group">
        <button type="button" onClick={() => onNavigate('PREV')}>
          {messages?.previous || 'Back'}
        </button>
        <button type="button" onClick={() => onNavigate('TODAY')}>
          {messages?.today || 'Today'}
        </button>
        <button type="button" onClick={() => onNavigate('NEXT')}>
          {messages?.next || 'Next'}
        </button>
      </div>

      {/* Date Picker */}
      <div className="rbc-toolbar-label">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MMMM d, yyyy"
          style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}
          locale={de}
          customInput={
            <button type="button" className="date-picker-label">
              {label}
            </button>
          }
          popperClassName="custom-datepicker-popper"
          popperPlacement="bottom-start"
          showPopperArrow={false} // Entfernt den Popper-Pfeil für ein sauberes Design
          portalId="root-portal" // Rendern in ein Portal für bessere Z-Index-Steuerung
       
        />
      </div>

      {/* Ansichtsauswahl */}
      <div className="rbc-btn-group">
        <button
          type="button"
          className={view === 'month' ? 'rbc-active' : ''}
          onClick={() => {
            setView('month');
            if (plannerActive) setPlannerActive(false); // Planner deaktivieren
          }}
        >
          {messages?.month || 'Month'}
        </button>
        <button
          type="button"
          className={view === 'week' ? 'rbc-active' : ''}
          onClick={() => {
            setView('week');
            if (plannerActive) setPlannerActive(false); // Planner deaktivieren
          }}
        >
          {messages?.week || 'Week'}
        </button>
        <button
          type="button"
          className={view === 'day' ? 'rbc-active' : ''}
          onClick={() => {
            setView('day');
            if (plannerActive) setPlannerActive(false); // Planner deaktivieren
          }}
        >
          {messages?.day || 'Day'}
        </button>
        <button
          type="button"
          className={view === 'agenda' ? 'rbc-active' : ''}
          onClick={() => {
            setView('agenda');
            if (plannerActive) setPlannerActive(false); // Planner deaktivieren
          }}
        >
          {messages?.agenda || 'Agenda'}
        </button>
        <button
          type="button"
          className={plannerActive ? 'rbc-active' : ''}
          onClick={togglePlanner} // Nur Planner-Status toggeln
        >
          {messages?.planner || 'Planner'}
        </button>
      </div>
    </div>
  );
}

export default CustomToolbar;
