import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Container, EmployeeList } from './MitarbeiterList.element';
import { EmployeesContext, EventsContext } from '../../context/EveMitContext';

const MitarbeiterList = () => {
  const { employees } = useContext(EmployeesContext);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [allVisible, setAllVisible] = useState(true);
  const { setFilteredEmployees } = useContext(EventsContext);

  useEffect(() => {
    const storedSelectedIds = JSON.parse(localStorage.getItem('selectedEmployeeIds')) || [];
    const allEmployeeIds = employees.map((emp) => emp.mitarbeiter_id);

    if (storedSelectedIds.length) {
      setSelectedEmployeeIds(storedSelectedIds);
      setFilteredEmployees(storedSelectedIds); // Only show events for selected employees
      setAllVisible(storedSelectedIds.length === allEmployeeIds.length);
    } else {
      setSelectedEmployeeIds(allEmployeeIds);
      setFilteredEmployees([]); // Show all events if no filter is applied
    }
  }, [employees, setFilteredEmployees]);

  const toggleEmployeeVisibility = (employeeID) => {
    let newSelectedIds;

    if (selectedEmployeeIds.includes(employeeID)) {
      // Remove employee from selected list
      newSelectedIds = selectedEmployeeIds.filter((id) => id !== employeeID);
    } else {
      // Add employee to selected list
      newSelectedIds = [...selectedEmployeeIds, employeeID];
    }

    setSelectedEmployeeIds(newSelectedIds);

    // Update filteredEmployees to show only selected employees’ events
    setFilteredEmployees(
      newSelectedIds.length === employees.length ? [] : newSelectedIds
    );

    localStorage.setItem('selectedEmployeeIds', JSON.stringify(newSelectedIds));
    setAllVisible(newSelectedIds.length === employees.length); // Update icon state
  };

  const toggleAllEmployeesVisibility = () => {
    if (allVisible) {
      // Hide all employees
      const allEmployeeIds = employees.map((e) => e.mitarbeiter_id);

      setSelectedEmployeeIds([]);
      setFilteredEmployees([allEmployeeIds]);
      
    } else {
      // Show all employees
      const allEmployeeIds = employees.map((e) => e.mitarbeiter_id);
      setSelectedEmployeeIds(allEmployeeIds);
      setFilteredEmployees([]);
    }

    setAllVisible(!allVisible);
    localStorage.setItem(
      'selectedEmployeeIds',
      JSON.stringify(!allVisible ? employees.map((e) => e.mitarbeiter_id) : [])
    );
  };

  return (
    <Container>
      <FontAwesomeIcon
        icon={!allVisible ? faEyeSlash : faEye}
        onClick={toggleAllEmployeesVisibility}
        style={{ cursor: 'pointer' }}
      />
      <EmployeeList>
        {employees.map((employee) => (
          <li
            key={employee.mitarbeiter_id}
            onClick={() => toggleEmployeeVisibility(employee.mitarbeiter_id)}
            style={{
              backgroundColor: employee.farbe,
              color: selectedEmployeeIds.includes(employee.mitarbeiter_id) ? '#495057' : '#dc3545',
            }}
          >
            <span>{employee.name}</span>
            <FontAwesomeIcon
              icon={selectedEmployeeIds.includes(employee.mitarbeiter_id) ? faEye:faEyeSlash }
              onClick={(e) => {
                e.stopPropagation();
                toggleEmployeeVisibility(employee.mitarbeiter_id);
              }}
              style={{ cursor: 'pointer' }}
            />
          </li>
        ))}
      </EmployeeList>
    </Container>
  );
};

export default MitarbeiterList;
