import React, { memo } from "react";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";

const CustomEventTooltip = memo(({ event }) => {
  const employeeNames = event.employee_names || [];

  const startDate = new Date(event.start_datetime);
  const endDate = new Date(event.end_datetime);

  const formattedStart = format(startDate, "EEE, d MMM yyyy HH:mm", { locale: deLocale });
  const sameDay = format(startDate, "yyyy-MM-dd") === format(endDate, "yyyy-MM-dd");
  const formattedEnd = sameDay
    ? format(endDate, "HH:mm", { locale: deLocale })
    : format(endDate, "EEE, d MMM yyyy HH:mm", { locale: deLocale });

  return (
    <div className="custom-tooltip">
      <div>
        <strong>Titel: {event.title || "N/A"}</strong>
      </div>
      <div>
        {formattedStart} - {formattedEnd}
      </div>
      {event.description && (
        <div style={{ marginTop: "8px" }}>{event.description}</div>
      )}
      {employeeNames.length > 0 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            marginTop: "8px",
          }}
        >
          {employeeNames.map((emp, index) => (
            <span
              key={index}
              style={{
                backgroundColor: emp.color || "#ddd",
                color: "#333",
                fontWeight: "bold",
                padding: "5px 8px",
                borderRadius: "12px", // Rounded tags
                display: "inline-block",
                textAlign: "center",
                fontFamily:'Segoe UI'
              }}
            >
              {emp.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
});

export default CustomEventTooltip;
