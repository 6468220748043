import React, { useState, useContext } from 'react';
import {
  TabButton,
  TabContent,
  TabTitle,
  TabText,
  TabsContainer,
  ButtonContainer,
} from './Repeat.element';
import API from '../../context/axsioSetup';
import { EventsContext } from '../../context/EveMitContext';
import useEmployeeSelection from '../../Hook/useEmployeeSelection';

import format from 'date-fns/format';
import de from 'date-fns/locale/de';

const RepeatKomponent = ({ newEvent, setShowEventForm,setNewEvent }) => {
  const options = ['Täglich', 'Mo-Fr', 'Wöchentlich', 'Monatlich', 'Jährlich'];
  const { fetchEventsAndUpdate } = useContext(EventsContext);
  const { generateEventColor } = useEmployeeSelection();

console.log(newEvent);



  const [repeatData, setRepeatData] = useState({
    option: options[0],
    weekdays: new Set(),
    selectedOption: 'never',
    startdatum: newEvent.start,
    enddatum: newEvent.end,
    end: { 
      type: 'date', 
      value: newEvent.start ? new Date(newEvent.start).toISOString().split("T")[0] : '' 
    },
    ab: newEvent.start,
    title: newEvent.title,
    description: newEvent.description,
    users: newEvent.selectedEmployees,
    color: '',
    monthlyOption: 'sameDay',
    nthWeekday: 'first',
    weekday: 'Mo',
  });
  
  console.log(newEvent.start);
  
  //console.log(repeatData.users);
  const [activeTab, setActiveTab] = useState(options[0]);

  const isWeekdaySelected = (weekday) => repeatData.weekdays.has(weekday);
  const formatDate = (date) => {
    console.log(date);
    
    return new Date(date).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };
  const toggleWeekdaySelection = (weekday) => {
    const updatedSelection = new Set(repeatData.weekdays);
    if (updatedSelection.has(weekday)) {
      updatedSelection.delete(weekday);
    } else {
      updatedSelection.add(weekday);
    }
    setRepeatData({ ...repeatData, weekdays: updatedSelection });
  };

  const sendRecurrenceToServer = async () => {
    // Validierung der Start- und Enddaten
    if (isNaN(new Date(newEvent.start)) || isNaN(new Date(newEvent.end))) {
      alert('Ungültiges Start- oder Enddatum');
      return;
    }

    // Formatierung der Daten ins richtige Format
    const formattedStartDate = format(new Date(newEvent.start), 'yyyy-MM-dd HH:mm:ss', { locale: de });
    const formattedEndDate = format(new Date(newEvent.end), 'yyyy-MM-dd HH:mm:ss', { locale: de });
 
    try {
      const response = await API.post('/save-recurrence', {
        ...repeatData,
        title:newEvent.title,
        description: newEvent.description,
        startdatum: formattedStartDate,
        users: newEvent.selectedEmployees,
        enddatum: formattedEndDate,
        weekdays: Array.from(repeatData.weekdays),
       color: generateEventColor(newEvent.selectedEmployees),
        end: {
          value: repeatData.end.value,
          type: repeatData.selectedOption === 'on' ? 'date' : 'number',
        },
      });

      if (response.status !== 201) {
        throw new Error(`Failed to save recurrence: ${response.statusText}`);
      }

      alert('Termin erfolgreich gespeichert!');
      setNewEvent({
        title: '',
        startTimeEvent: null,
        endTimeEvent: null,
        description: '',
        selectedEmployees: [],
      });

      await fetchEventsAndUpdate();
      setShowEventForm(false);
    } catch (error) {
      console.error('Fehler beim Senden an den Server:', error.message);
      alert('Fehler beim Speichern des Termins');
    }
};


  return (
    <TabsContainer>
      <ButtonContainer>
        {options.map((option, index) => (
          <TabButton
            key={index}
            onClick={() => {
              setActiveTab(option);
              setRepeatData({ ...repeatData, option });
            }}
          >
            {option}
          </TabButton>
        ))}
      </ButtonContainer>

      {options.map((option) => (
        <TabContent key={option} isActive={activeTab === option}>
          <TabTitle>{option}</TabTitle>

          {/* Wöchentliche Wiederholung */}
          {option === 'Wöchentlich' && (
            <TabText>
              Wiederholt an folgenden Tagen:<br></br>
               {` ab ${formatDate(newEvent.start)}`}
              <div>
                {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map((weekday) => (
                  <label key={weekday} style={{ marginRight: '10px' }}>
                    <input
                      type="checkbox"
                      checked={isWeekdaySelected(weekday)}
                      onChange={() => toggleWeekdaySelection(weekday)}
                    />
                    {weekday}
                  </label>
                ))}
              </div>
            </TabText>
          )}

          {/* Monats-Wiederholung */}
          {option === 'Monatlich' && (
            <TabText>
              Wiederholt monatlich:<br></br>
              {` ab ${formatDate(newEvent.start)}`}
              <div>
                <label>
                  <input
                    type="radio"
                    name="monthlyOption"
                    value="sameDay"
                    checked={repeatData.monthlyOption === 'sameDay'}
                    onChange={(e) => setRepeatData({ ...repeatData, monthlyOption: e.target.value })}
                  />
                  Am gleichen Tag des Monats
                </label>
                <label>
                  <input
                    type="radio"
                    name="monthlyOption"
                    value="nthWeekday"
                    checked={repeatData.monthlyOption === 'nthWeekday'}
                    onChange={(e) => setRepeatData({ ...repeatData, monthlyOption: e.target.value })}
                  />
                  Am
                </label>
                {repeatData.monthlyOption === 'nthWeekday' && (
                  <div>
                    <select
                      value={repeatData.nthWeekday}
                      onChange={(e) => setRepeatData({ ...repeatData, nthWeekday: e.target.value })}
                    >
                      <option value="first">Ersten</option>
                      <option value="second">Zweiten</option>
                      <option value="third">Dritten</option>
                      <option value="fourth">Vierten</option>
                      <option value="last">Letzten</option>
                    </select>
                    <select
                      value={repeatData.weekday}
                      onChange={(e) => setRepeatData({ ...repeatData, weekday: e.target.value })}
                    >
                      {['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'].map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </TabText>
          )}

          {/* Täglich / Mo-Fr / Jährlich */}
          {option !== 'Wöchentlich' && option !== 'Monatlich' && (
            <TabText>
         Wiederholt {`${option} ab ${formatDate(newEvent.start)}`}
            </TabText>
          )}

          {/* Recurrence End Option */}
          <TabText>
            Und endet
            <select
              onChange={(e) => setRepeatData({ ...repeatData, selectedOption: e.target.value })}
              value={repeatData.selectedOption}
            >
              <option value="never">einmal</option>
              <option value="after">nach</option>
              <option value="on">am</option>
            </select>
            {repeatData.selectedOption === 'after' && (
              <span>
                <input
                  type="number"
                  placeholder="Anzahl"
                  onChange={(e) =>
                    setRepeatData({
                      ...repeatData,
                      end: { ...repeatData.end, value: e.target.value, type: 'number' },
                    })
                  }
                />
              </span>
            )}
           {repeatData.selectedOption === 'on' && (
  <input
    type="date"
    placeholder="Datum"
    value={repeatData.end.value}
    onChange={(e) =>
      setRepeatData({
        ...repeatData,
        end: { ...repeatData.end, value: e.target.value, type: 'date' },
      })
    }
  />
)}

          </TabText>

          {/* Save Button */}
          <TabButton onClick={sendRecurrenceToServer}>Speichern</TabButton>
        </TabContent>
      ))}
    </TabsContainer>
  );
};

export default RepeatKomponent;
