import React, { useState, useEffect, useContext } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import RepeatKomponent from './RepeatKomponent';
import { FormContainer, FormHeader1, InputEv, Button, Header, DataContainer, DataContainerDatePicker ,StyledDatePicker } from './EventForm.element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import API from '../../context/axsioSetup';
import { EmployeesContext, EventsContext } from '../../context/EveMitContext';
import useEmployeeSelection from '../../Hook/useEmployeeSelection';
import format from 'date-fns/format';
import Select from 'react-select';

registerLocale('de', de);

function EventForm({ newEvent, setNewEvent, setShowEventForm }) {
  const { employees } = useContext(EmployeesContext);
  const { fetchEventsAndUpdate } = useContext(EventsContext);
  const { selectedEmployees,  generateEventColor } = useEmployeeSelection([], employees);
  const [showRepeatComponent, setShowRepeatComponent] = useState(false);
  const [isGanzerTag, setIsGanzerTag] = useState(false);

console.log(selectedEmployees);

  useEffect(() => {
    if (isGanzerTag) {
      const startOfDay = new Date(newEvent.startTimeEvent || new Date());
      startOfDay.setHours(0, 0, 0, 0);
      const nextDay = new Date(startOfDay);
      nextDay.setDate(nextDay.getDate() + 1);
      nextDay.setHours(0, 0, 0, 0);

      setNewEvent({ ...newEvent, startTimeEvent: startOfDay, endTimeEvent: nextDay });
    }
  }, [isGanzerTag]);

  const handleAddEvent = async () => {
    if (!newEvent.title.trim()) {
      window.alert('Bitte füllen Sie Titel und Beschreibung aus.');
      return;
    }
  
    if (!newEvent.startTimeEvent || !newEvent.endTimeEvent) {
      window.alert('Bitte wählen Sie Start- und Endzeit aus.');
      return;
    }
  
    if (newEvent.selectedEmployees.length === 0) {
      window.alert('Bitte wählen Sie mindestens einen Mitarbeiter aus.');
      return;
    }
  
    const formattedStartDate = format(newEvent.startTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: de });
    const formattedEndDate = format(newEvent.endTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: de });
  
    const newEventObj = {
      title: newEvent.title,
      start: formattedStartDate,
      end: formattedEndDate,
      users: newEvent.selectedEmployees.map(emp => emp.id),  // Nur die IDs der Mitarbeiter werden hier gesendet
      color: generateEventColor(newEvent.selectedEmployees),
      description: newEvent.description,
    };
  
    try {
      const response = await API.post('/addEvent', newEventObj);
  
      if (response.status === 200 || response.status === 201) {
        window.alert('Event erfolgreich hinzugefügt.');
        setNewEvent({
          title: '',
          startTimeEvent: null,
          endTimeEvent: null,
          description: '',
          selectedEmployees: [],
        });
  
        setShowRepeatComponent(false);
        setShowEventForm(false);
        fetchEventsAndUpdate();
      } else {
        console.error('Fehler beim Hinzufügen des Events');
      }
    } catch (error) {
      console.error('Fehler beim Bearbeiten des Events:', error);
      window.alert('Ein Fehler ist aufgetreten: ' + error.message);
    }
  };
  

  const handleRepeatCheckbox = (e) => {
    const isDataComplete = newEvent.title.trim() ;
    if (e.target.checked && !isDataComplete) {
      window.alert('Bitte vervollständigen Sie die Eingaben für Titel, Beschreibung und Mitarbeiter.');
    } else {
      setShowRepeatComponent(e.target.checked);
    }
  };

  const handleGanzerTagCheckbox = (e) => {
    setIsGanzerTag(e.target.checked);
  };

  // Optionen für das Select-Feld
  const employeeOptions = employees.map(employee => ({
    value: employee.name,
    label: employee.name,
    color: employee.farbe,
    id:employee.mitarbeiter_id
  }));
  // Funktion, die ein Datum mit spezifischen Stunden und Minuten erstellt
const getDateWithTime = (hours, minutes) => {
  const date = new Date();
  date.setHours(hours, minutes, 0, 0); // Setzt Stunden und Minuten auf das gegebene Datum
  return date;
};

// Standardzeiten einstellen, z.B. 10:00 Uhr und 10:30 Uhr
const defaultStartTime = getDateWithTime(10, 0);
const defaultEndTime = getDateWithTime(10, 30);
  return (
    <FormContainer id='form-container' showrepeatComponent={showRepeatComponent}>
      <Header>
        <FontAwesomeIcon icon={faTimes} onClick={() => setShowEventForm(false)} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', fontSize:'25px' }} />
        <FormHeader1>Neuer Termin</FormHeader1>
      </Header>
      
      <InputEv type="text" placeholder="Titel" value={newEvent?.title || ''} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} />
      <InputEv type="text" placeholder="Beschreibung" value={newEvent?.description || ''} onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })} />

      <Select
  options={employeeOptions}
  onChange={(selectedOptions) => {
    const selectedEmployees = selectedOptions.map(option => ({
      id: option.id,  // Die ID des ausgewählten Mitarbeiters
      name: option.label,  // Der Name des ausgewählten Mitarbeiters
      color: option.color  // Die Farbe des Mitarbeiters
    }));
    setNewEvent({ ...newEvent, selectedEmployees});
  }}
  isMulti
  placeholder="Welche Mitarbeiter"
  getOptionLabel={(option) => (
    <div style={{ backgroundColor: option.color }}>
      {option.label}
    </div>
  )}
  getOptionValue={(option) => option.value}
/>

      <DataContainerDatePicker showrepeatComponent={showRepeatComponent}>
        <div>
          Von:
          <StyledDatePicker
            selected={new Date(newEvent.startTimeEvent || defaultStartTime)}
            onChange={(date) => {
              if (!isGanzerTag) {
                setNewEvent({ ...newEvent, startTimeEvent: date });
              }
            }}
            showTimeSelect={!isGanzerTag}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Zeit"
            dateFormat="Pp"
            locale="de"
          />
        </div>
        <div>
          Bis:
          <StyledDatePicker
        selected={new Date(newEvent.endTimeEvent || defaultEndTime)}
            onChange={(date) => {
              if (!isGanzerTag) {
                setNewEvent({ ...newEvent, endTimeEvent: date });
              }
            }}
            showTimeSelect={!isGanzerTag}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Zeit"
            dateFormat="Pp"
            locale="de"
          />
        </div>
      </DataContainerDatePicker>

      <DataContainer>
        <div>
          <input type="checkbox" id="ganzerTag" name="ganzerTag" onChange={handleGanzerTagCheckbox} />
          <label htmlFor="ganzerTag">Ganzer Tag</label>
        </div>
        <div>
          <input type="checkbox" id="wiederholen" name="wiederholen" onChange={handleRepeatCheckbox} />
          <label htmlFor="wiederholen">Wiederholen</label>
        </div>
      </DataContainer>

      {showRepeatComponent && (
        <RepeatKomponent
          newEvent={newEvent}
          users={selectedEmployees}
        
          setShowEventForm={setShowEventForm}
          fetchEventsAndUpdate={fetchEventsAndUpdate}
          setNewEvent={setNewEvent}
        />
      )}

      <Button onClick={handleAddEvent} showRepeatComponent={showRepeatComponent}>Hinzufügen</Button>
    </FormContainer>
  );
}

export default EventForm;
