import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginComponent from './pages/Login/LoginComponent';
import Home from './pages/HomePage/HomePage';
import SearchEvent from './pages/SearchEvent/SearchEvent';
import { LanguageProvider } from './context/LanguageContext';
import PrivateRoute from './PrivateRoute';
import { AppContextProvider} from './context/EveMitContext';
import SettingsPage from './pages/Setting/SettingsPage';
import { SettingsProvider } from './context/SettingsContext';

function App() {
  return (
  
      <AppContextProvider>
          <LanguageProvider>
            <SettingsProvider>
       
          <Routes>
            {/* Redirect from root path to login */}
            <Route path="/" element={<Navigate to="/login" replace />} />

            {/* Define your actual login and private routes */}
            <Route path="/login" element={<LoginComponent />} />
            <Route 
              path="/user/:userId" 
              element={<PrivateRoute><Home /></PrivateRoute>} 
            />
            <Route 
              path="/user/:userId/search" 
              element={<PrivateRoute><SearchEvent /></PrivateRoute>} 
            /> 
             <Route path="/settings" element={<SettingsPage />} />
            {/* Optional: Add a wildcard route to catch undefined paths<
            {/* <Route path="*" element={<ErrorPage />} /> */}
          </Routes>
          </SettingsProvider>
        
    </LanguageProvider>
    </AppContextProvider>
    
  );
}

export default App;
