import React, { createContext, useState, useEffect } from 'react';
import API from './axsioSetup'; // Importiere den API-Client
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const initializeAuthState = () => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      try {
        const decodedToken = jwtDecode(accessToken);
        setUser(decodedToken);
        setRole(decodedToken.role);
      } catch (error) {
        console.error('Invalid access token:', error);
        localStorage.removeItem('access_token');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    initializeAuthState();
  }, []);

  // Login-Funktionjwt
  const login = async (username, password) => {
    // Check if user is already logged in
    const existingToken = localStorage.getItem('access_token');
    if (existingToken) {
      // Optionally log out the user or prompt them to confirm the login override
      await logout();  // Automatically log out existing user
    }
  
    try {
      setLoading(true);
      const { data } = await API.post('/login', { username, password });
      console.log('Response from Login Data', data);
  
      // Store new token and session details
      localStorage.setItem('access_token', data.accessToken);
      localStorage.setItem('session_id', data.sessionId);
      const decodedToken = jwtDecode(data.accessToken);
      console.log('decoded ',decodedToken);
      
      setUser(decodedToken);
      setRole(decodedToken.role);
      
      // Redirect based on user role
      navigate(decodedToken.role === 'filiale' ? `/user/${decodedToken.username}` : '/login');
    } catch (error) {
      console.error('Login failed:', error);
      throw new Error('Invalid login credentials');
    } finally {
      setLoading(false);
    }
  };
  

  // Logout-Funktion
  const logout = async () => {
    try {
      const sessionId = localStorage.getItem('session_id');
      await API.post('/logout', { session_id: sessionId });
    } catch (error) {
      console.error('Logout request failed:', error);
    } finally {
      localStorage.removeItem('access_token');
      localStorage.removeItem('session_id');
      setUser(null);
      setRole(null);
      navigate('/login');
    }
  };

  return (
    <AuthContext.Provider value={{ user, role, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
