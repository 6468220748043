import React, { memo, useCallback,useContext } from 'react';
import { Calendar } from 'react-big-calendar';
import format from 'date-fns/format';
import { Tooltip } from 'react-tooltip';
import CustomEventTooltip from '../CustomEventTooltip';
import CustomToolbarSched from './CustumToolbarSched';
import useCalendarState from '../../../Hook/useCalendarState';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import EventForm from '../EventForm';
import EventEdit from '../EventEdit';
import CustomResourceHeader from './CustomResourceHeader';
import { SettingsContext } from '../../../context/SettingsContext';
const CustomToolbar = ({ label }) => (
  <div style={{ textAlign: 'center', padding: '10px 0' }}>
    <span>{label}</span>
  </div>
);
const CalendarWithDragAndDrop = withDragAndDrop(Calendar);
const MultiCalendars = memo(({
  localizer,
  filteredEvents,
  resources,
  selectedDate,
  setSelectedDate,
  view,
  onView,
  multiCount,
  setMultiCount,

  setPlannerActive,
  setMultiSettings,
}) => {
  // Hook-Aufruf außerhalb des Callbacks
  const {
    handleSelectSlot, eventPropGetter,moveEvent,resizeEvent,  showEventForm,handleEventHover,  calendarRef, setShowEventForm,setNewEvent,showEventEdit, setShowEventEdit,hoveredEvent,newEvent
  } = useCalendarState();
  const { settings } = useContext(SettingsContext);
  const renderCalendar = useCallback(
    (index) => {
      const dateOffset = index * (view === 'multiDay' ? 1 : 7);
      const calendarDate = new Date(selectedDate);
      calendarDate.setDate(selectedDate.getDate() + dateOffset);
     
      return (
        <div key={index} style={{ margin: '20px 0', border: '1px solid #ccc', padding: '10px' }}>
          <CustomToolbarSched
            view={view}
            onView={onView}
            multiCount={multiCount}
            onMultiChange={setMultiCount}
            date={selectedDate}
            setPlannerActive={setPlannerActive}
            setMultiSettings={setMultiSettings}
          />
          <CalendarWithDragAndDrop
            localizer={localizer}
            style={{ height: '100vh' }}
            events={filteredEvents}
            date={calendarDate}
            view={view === 'multiDay' ? 'day' : 'week'}
            step={settings.step}
            timeslots={settings.timeslots}
            allDay={true}
            startAccessor={(event) => new Date(event.start_datetime)}
            endAccessor={(event) => new Date(event.end_datetime)}
            resources={resources}
            min={new Date('2023-07-20T07:00:00')}
            max={new Date('2023-07-20T21:00:00')}
            eventPropGetter={eventPropGetter}
            onSelectSlot={handleSelectSlot}
            selectable
            draggable
            dayLayoutAlgorithm={'no-overlap'}
            popup
            onEventDrop={moveEvent}
            onSelectEvent={handleEventHover}
            onEventResize={resizeEvent}
      
            components={{
              resourceHeader: CustomResourceHeader, 
              toolbar: CustomToolbar,
              event: ({ event }) => (
                <div data-tooltip-id={`event-tooltip-${event.event_id}`} className="rbc-events-container">
                  <div className="event-title">
                    <span className="event-start">
                      {format(new Date(event.start_datetime), 'HH:mm')}
                    </span>{' '}
                    - {event.title}
                  </div>
                  <Tooltip id={`event-tooltip-${event.event_id}`}>
                    <CustomEventTooltip event={event} />
                  </Tooltip>
                </div>
              ),
            }}
          />
             {showEventForm && (
        <EventForm
          setShowEventForm={setShowEventForm}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
        />
      )}
      {showEventEdit && hoveredEvent && (
        <EventEdit
          hoveredEvent={hoveredEvent}
       
          setShowEventEdit={setShowEventEdit}
          setNewEvent={setNewEvent}
        />
      )}
        </div>
      );
    },
    [
      localizer,
      filteredEvents,
      selectedDate,
      view,
      eventPropGetter,
      resources,
      onView,
      multiCount,
      setMultiCount,
      handleSelectSlot,
      moveEvent,
      resizeEvent,
      handleEventHover,
      setPlannerActive,
      setMultiSettings,
    ]
  );

  return <div>{Array.from({ length: multiCount }).map((_, index) => renderCalendar(index))}</div>;
});

export default MultiCalendars;
