import styled from 'styled-components';

// Container for the entire repeat component
export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  font-family: 'Segoe UI';

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

// Container for tab buttons
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    gap: 5px;
  }
`;

// Styled Component for the tab buttons
export const TabButton = styled.button`
  background-color: ${(props) => (props.isActive ? '#0056b3' : '#007bff')};
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
  width: auto;
  min-width: 100px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#003f8a' : '#0056b3')};
  }

  @media (max-width: 480px) {
    padding: 8px 16px;
    font-size: 13px;
  }
`;

// Styled Component for tab content
export const TabContent = styled.div`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px 0;
  width: 100%;
  background: ${(props) => (props.isActive ? 'lightblue' : 'white')};

  @media (max-width: 768px) {
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

// Tab title styling
export const TabTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

// Paragraph text within tab content
export const TabText = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;

  // Improved styling for form elements within the tab
  input[type="date"],
  input[type="number"],
  select {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }

  label {
    margin-right: 10px;
    font-weight: 500;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;

    input[type="date"],
    input[type="number"],
    select {
      font-size: 0.9rem;
      padding: 6px;
    }
  }
`;

// A more distinct button style for saving within the tabs
export const SaveButton = styled.button`
  background-color: #28a745;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;
